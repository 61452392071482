
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Data from "./login/components/data"
import "bootstrap/dist/css/bootstrap.min.css"

const initialState = {
  activeSection: 1
}

function reducer(state = initialState, action) {
  console.log('A :', action);
  switch (action.type) {
    case 'ACTIVE_SECTION':
      return {
        activeSection: action.section
      };
    default:
      return state;
  }
}

const store = createStore(reducer);

store.dispatch({
  type: 'ACTIVE_SECTION',
  section: 0
})

const Application = () => (
  <Provider store={store}>
    <Data />
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
