import React, { useEffect } from "react";
import Signup from "./Signup";

import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
// import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
// import UpdateProfile from "./UpdateProfile"
import App from "../../component/App";
import "./style.css";

function Data() {
  return (
    
    <div className="">
      <div className="">
        <Router>
          <AuthProvider>
            <Switch>
              {/* <PrivateRoute exact path="/" component={App} /> */}
              <Route path="/" component={App} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    </div>

  );
}

export default Data;
