import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { mark } from "../../../asset/image";
import "./maxpain.css";
const sheetIdxToUpdate = [1, 2];

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};
class Maxpain extends React.Component {
  constructor(props) {
    super(props);
    this.state = { metaData: [], activeSection: 0 };
  }

  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    props.metaData.map((item) => {
      if (sheetIdxToUpdate.includes(item.sheetIdx)) {
        item.data = item.data.map((x) => ({
          ...x,
          key: x._rawData ? parseInt(x._rawData[0]) : "",
        }));
      }
    });
    return props;
  }

  render() {
    const { metaData } = this.props;
    if (metaData.length) {
      return (
        <div className="sectionContent flexWrap">
          {/* <div className="hamza"><p>Max plan</p></div> */}
          {metaData.map((mD, index) => {
            return mD.data.length > 2 ? (
              <>
              {console.log("mmmmddd",mD)}
                {index === 0 ? null : (
                  <>
                    {" "}
                    <div className="max-mid">
                      <div className="max_mark">
                        <img src={mark} alt="" />
                      </div>
                      <div className="hamza">
                        <p>Max plan</p>
                      </div>
                      
                    </div>
                  </>
                )}

                <div key={mD.sheetId} className="chartWrapper">
                  <div className="maxpain1_title">
                    {index ===0 ? <h3>NIFTY</h3>:(
                      <h3>BANK NIFTY</h3>
                    )}
                    
                  </div>

                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart width={500} height={300} data={mD.data}>
                      <XAxis dataKey="key" />
                      <YAxis tickFormatter={DataFormater} />
                      <Tooltip />
                      <Legend />
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                      <Line
                        type="monotone"
                        dataKey={mD.headers[1]}
                        stroke="#8884d8"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </>
            ) : mD.data.length ? (
				<> 
				
              <div className="Lower">
                <div key={mD.sheetId} className="chartWrapper mychartWrapper">
                {index ===2 ? <h3>NIFTY</h3>:(
                      <h3>BANK NIFTY</h3>
                    )}
                  <div >Lower Limit: {mD.data[0][mD.headers[0]]}</div>
                  <div>Upper Limit: {mD.data[0][mD.headers[1]]}</div>
                </div>

              </div>

			  {index ===  3? null
                  : (<div className="maxpian_bottom">
					  <div className="maxpain_mark_img"> <img src={mark} alt=""/></div>
                  <div className="max_bottom">
                    <h1>NORMAL DISTRIBUTION RANGE</h1>
                  </div>
				  </div>
                )}
                 
			  </>
            ) : (
              <div className="chartWrapper">No data</div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="sectionContent">No data</div>
        // <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        //   Nothing to show!
        // </div>
      );
    }
  }
}

export default Maxpain;
