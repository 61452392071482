import { Button } from '@material-ui/core';
import React from 'react';
import "./help.css";
// import Icon from '@material-ui/core/Icon';

const listItems = [
	{id: 1, label: 'Open Interest', desc: 'help desc for OI'},
	{id: 2, label: 'Max Pain', desc: 'help desc for max pain'},
	{id: 3, label: 'World Market', desc: 'help desc for WM'},
	{id: 4, label: 'Sentimeter', desc: 'help desc for Sentimeter'}
];

class Help extends React.Component {
  constructor(props) {
    super(props);
    console.log('C :', props); 
    this.state = { metaData: [], activeSection: 0, open: false };
    this.handleClose.bind(this)
    this.handleClick.bind(this)
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  handleClick(idx) {
    this.setState({
      activeSection: idx,
      open: true
    })
  }

  render() {
    const { open, activeSection } = this.state
    return (

      <div className='sectionContent colContainer'>
      <div className="upper_section">
      <div className="help_main">
        <div className="help_Contact_us">
          <h1>Contact Us</h1>
        </div>
        <div className="help_whatsapp">
          <h1> Whatsapp Us : +91-9019558746</h1>
          <h1> OR </h1>
        </div>
        <div className="help_contact_form">
        <input className="inputs" placeholder={"Email"} type="text" />
        <select className="inputs">
              <option>whats this about?</option>
            </select>
            <input className="descripition" placeholder={"Descripiton"} type="text" />
        </div>
        <div className="help_button_div">
          <button className="help_btn">submit</button>
        </div>
      </div>
      </div>
      <h3 className='sectionHeader'>Click on each tool to know more</h3>
         <div className='tools rowContainer'>
           {
             listItems.map( (item, idx) => {
               return (
                 <Button variant='contained' key={item.id} className='toolHelp' onClick={() => this.handleClick(idx)}>{item.label}</Button>
               );
             })
           }
         </div>
         <div id='myModal' className={ 'modal' + ( open ? ' open' : ' hidden')}>
           <div className='modal-content'>
             <span className='close' onClick={() => this.handleClose()}>&times;</span>
            <div className='modalBody'>{listItems[ activeSection ].desc}</div>
           </div>
         </div>
      </div>
    
      // <div className='sectionContent colContainer'>

      //   <h3 className='sectionHeader'>Click on each tool to know more</h3>
      //   <div className='tools rowContainer'>
      //     {
      //       listItems.map( (item, idx) => {
      //         return (
      //           <Button variant='contained' key={item.id} className='toolHelp' onClick={() => this.handleClick(idx)}>{item.label}</Button>
      //         );
      //       })
      //     }
      //   </div>
      //   <div id='myModal' className={ 'modal' + ( open ? ' open' : ' hidden')}>
      //     <div className='modal-content'>
      //       <span className='close' onClick={() => this.handleClose()}>&times;</span>
      //       <div className='modalBody'>{listItems[ activeSection ].desc}</div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Help;

