
import React from 'react'
import "./sentimeter.css"
import {line1,line2} from "../../../asset/image"

class Sentimeter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { metaData: [], activeSection: 0 };
  }

  componentDidMount() {
    console.log('Senti :', this.props);
  }

  render() {
    const { metaData } = this.props;
    if (metaData.length) {
      return (
        
        <div className='sectionContent'>
             <div className="sentimeter_main">
             <div className="sentimeter_left">
             <div className="sentimeter_left_heading">
               <h1>Event Calendar</h1>
             </div>
             <div className="sentimeter_chart">    
             {
            metaData.map( mD => {
              return <div className='keyEvents' key={mD.sheetId}>
                {
                  mD.headers.map( (h, hIdx) => {
                    return (
                      <div className='colContainer'>
                        <div className='header' key={'h'+hIdx}>
                          <span>{h}</span>
                        </div>
                        {
                          mD.data.map( (d, dIdx) => {
                            return (
                              <div className='data' key={'d'+dIdx+'h'+hIdx}>
                                <span>{d[h]}</span>
                              </div>
                            )
                          })
                        }   
                      </div>
                    )
                  })
                }
              </div>
            })
          }
             </div>
             </div>

             <div className="sentimeter_mid">
             <img src={line1} alt="line"/>
             </div>

             <div className="sentimeter_right">
               <div className="sentimeter_right_top">
                 <h1>News Feed</h1>
               </div>
               <div className="sentimeter_right_mid">
                 <img src={line2} alt=""/>
               </div>
               <div className="sentimeter_right_bottom">
                 <p>03/2021 : Enjoy the beta version for now. We are about to lift off very soon. </p>
               </div>
             </div>
               </div>          
        </div>
      )
		} else {
				return (
					<div className='sectionContent'>No data</div>
				)
    }
  }
}

export default Sentimeter;
