import React, { Component } from "react";
// import { Icon } from '@material-ui/core';
import Avatar from "@material-ui/core/Avatar";

// import { Avatar } from '@material-ui/core';
import FaceIcon from "@material-ui/icons/Face";
import app from "../firebase";
// import {currentUser} from "../login/contexts/AuthContext"

const listItems = [
  { id: 1, label: "Open Interest" },
  { id: 2, label: "Max Pain" },
  { id: 3, label: "World Market" },
  { id: 4, label: "Key Events" },
  { id: 5, label: "Help" },
];
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { activeSection: 0, panelActive: false };
  }
  logout() {
    app.auth().signOut();
  }

  handleClick(idx) {
    this.setState({
      activeSection: idx,
    });
    this.props.onSectionChange(idx);
  }
  

  render() {
    let { activeSection, panelActive } = this.props;
    return (
      <div className="rowContainer headerRow">
        <div className="logoRow">
          <span className="logo"></span>
          <span className="text">PTRADE</span>
        </div>
        <div className="navMenu rowContainer hide-md">
          {listItems.map((item, idx) => {
            return (
              <div
                key={item.id}
                className={
                  activeSection === idx
                    ? "navItem rowContainer active"
                    : "navItem rowContainer inactive"
                }
                onClick={() => this.handleClick(idx)}
              >
                <a className="linkText">{item.label}</a>
              </div>
            );
          })}
        </div>
        {/* <div className="nav_logout_div">
          <a onClick={this.logout} className="nav_logout">
            {" "}
            logout
          </a>
        </div> */}
        <div
          className="account"
          onMouseEnter={() => (panelActive = true)}
          onMouseLeave={() => (panelActive = false)}
        >
         

        {/* { !app.auth().currentUser.photoURL  == "" ? <Avatar alt="" src={app.auth().currentUser.photoURL} className="avatar" />:<FaceIcon />} */}
          <FaceIcon />
           {/* <Avatar alt="" src={app.auth().currentUser.photoURL} className="avatar" /> */}
          {/* <div>{app.auth().currentUser.photoURL}</div>  */}
          <div
            className={panelActive ? "active" : ""}
            onMouseEnter={() => (panelActive = true)}
            onMouseLeave={() => (panelActive = false)}
          ></div>
        </div>
      </div>
    );
  }
}

export default Navbar;
