import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { mark } from '../../../asset/image';
import "./worldmarket.css"
const colorPalette = ['#6DC990', '#9595C9', '#81C99D', '#C9726D', '#C9BB77']


class Worldmarket extends React.Component {
  constructor(props) {
    super(props);
    this.state = { metaData: [], activeSection: 0 };
  }

  componentDidMount() {
    console.log('COi :', this.props);
  }

  render() {
    const { metaData } = this.props;
    if (metaData.length) {
      return (
				<div className="sectionContent">
					{
						metaData.map( mD => {
							return mD.headers.length > 2 ? 
								<div key={mD.sheetId} className="chartWrapperFull colContainer">
									<h3>{mD.title} <img  src={mark} alt="" className="world_mark"/></h3>
									<ResponsiveContainer width="100%" height="85%">
										<LineChart data={mD.data}>
												<XAxis dataKey={mD.headers[0]}/>
												<YAxis/>
												<Tooltip />
												<Legend />
												<CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
												<Line type="monotone" dataKey={mD.headers[1]} stroke={colorPalette[0]} />
												<Line type="monotone" dataKey={mD.headers[2]} stroke={colorPalette[1]} />
												<Line type="monotone" dataKey={mD.headers[3]} stroke={colorPalette[2]} />
												<Line type="monotone" dataKey={mD.headers[4]} stroke={colorPalette[3]} />
												<Line type="monotone" dataKey={mD.headers[5]} stroke={colorPalette[4]} />
										</LineChart>
									</ResponsiveContainer>
								</div>
							:
							mD.data.length ?
								<div key={mD.sheetId} className="chartWrapper">
									<h3>{mD.title}</h3>
									<div>Lower Limit: {mD.data[0][mD.headers[0]]}</div>
									<div>Upper Limit: {mD.data[0][mD.headers[1]]}</div>
								</div>
							:
								<div>No data</div>
						})
					}        
				</div>
      )
		} else {
			return (
				<div>No data</div>
				// <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				// 	Nothing to show!
				// </div>
			)
		}
  }
}

export default Worldmarket;