import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import { connect } from "react-redux";
// import Oi from "./component/sections/oi/oiData";
import Maxpain from "../component/sections/maxpain/Maxpain";
import Worldmarket from "../component/sections/worldmarket/Worldmarket";
import Sentimeter from "../component/sections/sentimeter/Sentimeter";
import Help from "../component/sections/help/Help";
import { CircularProgress } from "@material-ui/core";
import { GoogleSpreadsheet } from "google-spreadsheet";

import Oii from "../component/sections/oi/Oii";


const sectionItems = [
  {
    id: 1,
    label: "Open Interest",
    component: Oii,
    sheetIndexes: [4, 5, 6, 7],
    data: {},
    
  },
  {
    id: 2,
    label: "Max Pain",
    component: Maxpain,
    sheetIndexes: [1, 2, 8, 9],
    data: {},
  },
  {
    id: 3,
    label: "World Market",
    component: Worldmarket,
    sheetIndexes: [3],
    data: {},
  },
  {
    id: 4,
    label: "Key Events",
    component: Sentimeter,
    sheetIndexes: [10],
    data: {},
  },
  { id: 5, label: "Help", component: Help, sheetIndexes: [], data: {} },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeSection: 0, sectionInView: "", masterData: [] };
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleScrollChange = this.handleScrollChange.bind(this);
    this.sectionRef = sectionItems.map((el) => ({
      id: el.id,
      ref: React.createRef(),
    }));
  }

  componentDidMount() {
    this.getData(); 
   
  }

  handleScrollChange(evt) {
 
    const { scrollLeft, clientWidth } = evt.target;
    const currSectionIdx =
      scrollLeft >= clientWidth ? Math.round(scrollLeft / clientWidth) : 0;
    const sectionChanged = currSectionIdx !== this.state.activeSection;
    if (sectionChanged && currSectionIdx < 5) {
      this.setState({ activeSection: currSectionIdx });
      this.props.dispatch({
        type: "ACTIVE_SECTION",
        section: currSectionIdx,
      });
    }
  }

  async getData() {
    let res = this.state.masterData.length ? this.state.masterData : [];
    const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    await doc.useServiceAccountAuth({
      private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
        /\\n/gm,
        "\n"
      ),
      client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
    });

    await doc.loadInfo();

    console.log("doc: ", doc);

    for (let section of sectionItems) {
      const { sheetIndexes } = section;
      for (let i of sheetIndexes) {
        const sheet = doc.sheetsByIndex[i];
        console.log("Sheet : ", sheet);

        const sheetData = await sheet.getRows();

        const obj = {
          sheetId: sheet.sheetId,
          headers: sheet.headerValues,
          sheetIdx: i,
          title: sheet.title,
          data: sheetData,
        };
        res = res.filter((item) => item.sheetIdx !== i);
        res.push(obj);
      }
    }
    this.setState({ masterData: res });


    // Comment above for loop and uncomment block if you want to fetch all data at once
    // let sheetCount = doc.sheetCount - 1;
    // while ( sheetCount > -1 ) {
    //   const sheet = doc.sheetsByIndex[ sheetCount ];
    //   console.log('Sheet : ', sheet)
    //   const sheetData = await sheet.getRows()
    //   const obj = { sheetId: sheet.sheetId, sheetIdx: sheetCount, title : sheet.title, headers: sheet.headerValues, data: sheetData }
    //   res.push( obj )
    //   console.log( obj )
    //   sheetCount--
    // }
    // console.log( 'all : ', res )
    // this.setState( { masterData: res } )
  }

  handleSectionChange(sectionIdx) {
    this.setState({ activeSection: sectionIdx });
    this.props.dispatch({
      type: "ACTIVE_SECTION",
      section: sectionIdx,
    });
    const elem = document.getElementById(`section-${sectionIdx}`);
    if (elem) {
      elem.scrollIntoView();
    }
  }

  render() {
    const { activeSection } = this.props;
    const { masterData } = this.state;

    return (
      <div className="app">
        <Navbar
          activeSection={activeSection}
          onSectionChange={this.handleSectionChange}
        />
   
        {masterData.length ? (
          <div className="sectionContainer" onScroll={this.handleScrollChange}>
            {sectionItems.map((item, idx) => {
              return (
                <section id={"section-" + idx} key={item.id}>
                  <item.component
                    activeSection={activeSection}
                    metaData={masterData.filter((d) =>
                      item.sheetIndexes.includes(d.sheetIdx)
                    )}
                 
                  />
     
                </section>
                
              );
            })}
       
          </div>
        ) : (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSection: state.activeSection,
});

export default connect(mapStateToProps)(App);
