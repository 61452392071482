import React from "react";
// import { GoogleSpreadsheet } from 'google-spreadsheet'
import { parse, stringify } from "flatted";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import "./oii.css";
import { mark } from "../../../asset/image";

class Oii extends React.Component {
  constructor(props) {
    super(props);
    this.state = { metaData: [], activeSection: 0 };
  }

  componentDidMount() {
  
    console.log("COi :", this.props);
  }

  
  render() {
    const { metaData } = this.props;



    localStorage.setItem("data", stringify(metaData)); 
  
    var metadata=parse(localStorage.getItem("data"))
   
    
    if (metadata.length) {
      return (
        <div className="sectionContent flexWrap">
          {metadata.map((mD, index) => {
            return mD.headers.length > 2 ? (
              <>
                <div
                  key={mD.sheetId}
                  className="  chartWrapper_text  chartWrapper"
                >
                  {/* <h3>{mD.title}</h3> */}

                  <div className="indexs">
                    {index === 0 ? (
                      <h3 className="h3">Weekly Expiry </h3>
                    ) : null}

                    {index === 1 ? <h3></h3> : null}

                    {index === 2 ? (
                      <h3 className="h3">Monthly Expiry </h3>
                    ) : null}
                  </div>
                  {index === 2 ? (
                    <div className="hh3">
                      <h3 className="brown">CE vs PE</h3>{" "}
                      <img src={mark} alt="" className="img" />
                    </div>
                  ) : null}
                  <div className="index3">
                    {" "}
                    {index === 3 ? <h3>Bank Nifty </h3> : null}
                  </div>

                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={mD.data}>
                      <XAxis dataKey={mD.headers[0]} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                      <Line
                        type="monotone"
                        dataKey={mD.headers[1]}
                        stroke="#8884d8"
                      />
                      <Line
                        type="monotone"
                        dataKey={mD.headers[2]}
                        stroke="#82ca9d"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </>
            ) : (
              <div key={mD.sheetId} className="chartWrapper">
                <h3>{mD.title}</h3>
                <div>Lower Limit: {mD.data[0][mD.headers[0]]}</div>
                <div>Upper Limit: {mD.data[0][mD.headers[1]]}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Nothing to show!
        </div>
      );
    }
  }
}

export default Oii;
